<template>
  <v-container fill-height fluid>
    <v-row align="center">
      <v-main>
        <div class="my-4 mx-5 language-item" v-for="service in services" :key="service.value">
          <v-btn :ripple="false" block large elevation="0" :color="selected == service.value ? '#627568' : '#efefef'"
            @click="selected = service.value;  useConfig(selected)" :class="selected == service.value ? 'white--text' : 'black--text'"
            :style="styleForService(service.value)">{{ service.name }}</v-btn>
        </div>
      </v-main>
    </v-row>
  </v-container>
</template>

<script>

import flavors from '../config';
import fontHelper from "../services/fonthelper";

export default {
  name: "Onboarding",
  data() {
    return {
      selected: "english"
    };
  },
  mounted() {
    if (this.language.startsWith("ug")) {
      this.selected = "uyghur";
    } else if (this.language.startsWith("zh")) {
      this.selected = "mandarin";
    } else if (this.language.startsWith("zh-hk")) {
      this.selected = "cantonese";
    } else if (this.language.startsWith("my")) {
      this.selected = "burmese";
    } else if (this.language.startsWith("lo")) {
      this.selected = "lao";
    } else if (this.language.startsWith("ko")) {
      this.selected = "korean";
    } else if (this.language.startsWith("km")) {
      this.selected = "khmer";
    } else if (this.language.startsWith("vi")) {
      this.selected = "vietnamese";
    } else if (this.language.startsWith("bo")) {
      this.selected = "tibetan";
    }
    var fonts = {};
    for (var flavor of Object.keys(flavors)) {
      if (flavors[flavor].fonts) {
        fonts = Object.assign(fonts, flavors[flavor].fonts);
      }
    }
    fontHelper.generateFontFaceImports(fonts, "fontsTemp");

    // Is the default ok? If not, use first service available
    if (!this.services.some(s => {
      return s.value == this.selected
    })) {
      this.selected = this.services[0].value;
    }
  },
  computed: {
    services() {
      let available = this.$store.state.flavorsAvailable;
      if (available) {
        let rgAvailable = available.split(",");
        return Object.keys(flavors).filter(function (key) {
          return rgAvailable.includes(key);
        }).map(function (key) {
          return { name: flavors[key].name, value: key };
        });
      }
      return Object.keys(flavors).map(function (key) {
        return { name: flavors[key].name, value: key };
      });
    },
    language: function () {
      var lang = navigator.language || navigator.userLanguage;
      return lang.toLowerCase();
    }
  },
  methods: {
    styleForService(svc) {
      const service = flavors[svc];
      return fontHelper.styleForFonts(service.fonts);
    },

    useConfig(config) {
      this.$logger.logLanguageSelect(config, false);
      this.$store.commit("setFlavor", config);
      this.done();
    },

    done() {
      this.$root.onboarded = true;
      this.$root.$router.replace("/");
    }
  }
};
</script>

<style scoped>
@import url("../assets/css/item-style.css");
</style>